export default {
  noTitle: '(pas de titre)',
  today: 'Aujourd\'hui',
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  pbehaviorPlanningLegend: {
    title: 'Légende',
    noData: 'Il n\'y a pas de dates d\'exception sur le calendrier',
  },
};
