export default {
  ticketUrlAndId: 'URL et identifiant du ticket',
  ticketUrlAndIdHelpText: 'L\'URL du ticket reçue de la réponse de l\'API peut être ajoutée aux informations dynamiques de l\'alarme.',
  dataFromOneStepAttention: 'Les données de la réponse de l\'API ne peuvent être extraites que d\'une étape d\'une règle.',
  ticketID: 'ID de ticket',
  ticketURL: 'URL du ticket',
  allowTicketURLTemplate: 'Autoriser les variables dans l\'URL',
  allowTicketIdTemplate: 'Autoriser les variables dans l\'ID',
  alarmFieldName: 'Nom du champ d\'alarme',
  customFields: 'Champs personnalisés',
  responseField: 'Champ de réponse',
  responseFieldHelpText: 'Champ de réponse API au format JSON d\'où provient le "{field}"',
  emptyResponse: 'Réponse vide',
  isRegexp: 'La valeur peut être une expression régulière',
  addWebhook: 'Ajouter un webhook',
  emptyFields: 'Aucun champ ajouté pour le moment',
  emptyWebhooks: 'Aucun webhook ajouté pour le moment',
  workflowIfStepFails: 'Si cette étape échoue :',
  continueWithNextStep: 'Continuer avec les étapes suivantes',
  webhookTicketDeclarationExist: 'La déclaration de ticket Webhook existe déjà dans une autre étape',
  headerFieldFromPreviousSteps: 'Champ d\'en-tête des étapes précédentes',
  responseFieldFromStep: 'Champ de réponse de l\'étape #N',
  responseFieldFromPreviousSteps: 'Champ de réponse des étapes précédentes',
  userId: 'Identifiant d\'utilisateur',
  actionInitiator: 'Initiateur d\'actions',
  triggerEventMessage: 'Déclencher un message d\'événement',
  noRulesForAlarms: 'Aucune règle à appliquer pour les alarmes sélectionnées',
  oneTicketForAlarms: 'Un ticket pour toutes les alarmes',
  applyRules: 'Appliquer les règles',
  noAckItems: 'Il y a {count} élément sans acquittement. L\'événement d\'acquittement pour l\'élément sera envoyé avant. | Il y a {count} éléments sans acquittement. Les événements d\'acquittement pour les éléments seront envoyés avant.',
  ruleName: 'Nom de la règle',
  checkSyntax: 'Vérifier la syntaxe',
  syntaxIsValid: 'La syntaxe est valide',
  ticketResources: 'Déclarer un ticket pour les ressources',
  errors: {
    webhookRequired: 'Les webhooks doivent être ajoutés',
    webhookTicketDeclarationRequired: 'Une déclaration de ticket webhook doit être définie',
    ticketRequired: 'Un ticket doit être ajouté',
  },
};
