import { render, staticRenderFns } from "./c-test-suite-chip.vue?vue&type=template&id=035efe7b&"
import script from "./c-test-suite-chip.vue?vue&type=script&lang=js&"
export * from "./c-test-suite-chip.vue?vue&type=script&lang=js&"
import style0 from "./c-test-suite-chip.vue?vue&type=style&index=0&id=035efe7b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports