import { ALARM_LIST_STEPS, ALARM_METRIC_PARAMETERS } from '@/constants';

export default {
  liveReporting: 'Définir un intervalle de dates',
  ackAuthor: 'Auteur de l\'acquittement',
  lastCommentAuthor: 'Auteur du dernier commentaire',
  lastCommentMessage: 'Message du dernier commentaire',
  metaAlarm: 'Meta-alarmes',
  acknowledge: 'Acquitter',
  ackResources: 'Acquitter les ressources',
  ackResourcesQuestion: 'Voulez-vous acquitter les ressources liées ?',
  actionsRequired: 'Des actions sont requises',
  acknowledgeAndDeclareTicket: 'Acquitter et déclarer un ticket',
  acknowledgeAndAssociateTicket: 'Acquitter et associer un ticket',
  otherTickets: 'D\'autres tickets sont disponibles dans le panneau d\'expansion',
  noAlarmFound: 'Aucune alarme n\'a été trouvée selon les modèles définis',
  associateTicketResources: 'Ticket associé pour les ressources',
  followLink: 'Suivez le lien "{title}"',
  hasBookmark: 'L\'alarme a un signet',
  filterByBookmark: 'Filtrer par signet',
  runTest: 'Exécuter le test',
  popups: {
    exportFailed: 'Impossible d\'exporter la liste des alarmes au format CSV',
    addBookmarkSuccess: 'Le signet a été ajouté',
    addBookmarkFailed: 'Quelque chose s\'est mal passé. Le signet n\'a pas été ajouté',
    removeBookmarkSuccess: 'Le signet a été supprimé',
    removeBookmarkFailed: 'Quelque chose s\'est mal passé. Le signet n\'a pas été supprimé',
  },
  actions: {
    titles: {
      ack: 'Acquitter',
      fastAck: 'Acquitter rapidement',
      ackRemove: 'Annuler l\'acquittement',
      pbehavior: 'Définir un comportement périodique',
      snooze: 'Mettre en veille',
      declareTicket: 'Déclarer un incident',
      associateTicket: 'Associer un ticket',
      cancel: 'Annuler l\'alarme',
      unCancel: 'Annuler l\'annulation de l\'alarme',
      fastCancel: 'Annulation rapide',
      changeState: 'Changer et verrouiller la criticité',
      variablesHelp: 'Liste des variables disponibles',
      history: 'Historique',
      linkToMetaAlarm: 'Lier à une méta-alarme',
      removeAlarmsFromManualMetaAlarm: 'Dissocier l\'alarme de la méta-alarme manuelle',
      removeAlarmsFromAutoMetaAlarm: 'Dissocier l\'alarme de la méta-alarme',
      comment: 'Commenter l\'alarme',
      exportPdf: 'Exporter l\'alarme au format PDF',
      addBookmark: 'Ajouter un signet',
      removeBookmark: 'Supprimer le signet',
    },
    iconsTitles: {
      ack: 'Acquittée',
      declareTicket: 'Déclarer un ticket',
      canceled: 'Annulée',
      snooze: 'Mise en veille',
      pbehaviors: 'Définir un comportement périodique',
      grouping: 'Meta-alarmes',
      comment: 'Commentée',
    },
    iconsFields: {
      ticketNumber: 'Numéro de ticket',
      parents: 'Causes',
      children: 'Conséquences',
    },
  },
  timeline: {
    by: 'par',
    launched: 'lancé',
    junit: 'JUnit',
    groupItems: 'Éléments de groupe',
    steps: {
      [ALARM_LIST_STEPS.statedec]: 'Sévérité diminuée',
      [ALARM_LIST_STEPS.stateinc]: 'Sévérité augmentée',
      [ALARM_LIST_STEPS.changeState]: 'Sévérité modifiée',
      [ALARM_LIST_STEPS.stateCounter]: 'Changements de sévérité regroupés (depuis le dernier changement de statut)',
      [ALARM_LIST_STEPS.statusdec]: 'Statut modifié à {status}',
      [ALARM_LIST_STEPS.statusinc]: 'Statut modifié à {status}',
      [ALARM_LIST_STEPS.resolve]: 'Alarme résolue',
      [ALARM_LIST_STEPS.activate]: 'Alarme activée',
      [ALARM_LIST_STEPS.snooze]: 'Alarme mise en veille pendant {duration}',
      [ALARM_LIST_STEPS.unsnooze]: 'Fin de veille de l\'alarme',
      [ALARM_LIST_STEPS.ack]: 'Alarme acquittée',
      [ALARM_LIST_STEPS.ackRemove]: 'Acquittement annulé',
      [ALARM_LIST_STEPS.pbhenter]: 'Comportement périodique activé',
      [ALARM_LIST_STEPS.pbhleave]: 'Comportement périodique désactivé',
      [ALARM_LIST_STEPS.assocTicket]: 'Ticket associé',
      [ALARM_LIST_STEPS.webhookStart]: 'Exécution du Webhook',
      [ALARM_LIST_STEPS.webhookInProgress]: 'Webhook exécuté par {author} en cours...',
      [ALARM_LIST_STEPS.webhookComplete]: 'Webhook exécuté avec succès',
      [ALARM_LIST_STEPS.webhookFail]: 'Webhook en échec',
      [ALARM_LIST_STEPS.declareTicket]: 'Ticket déclaré',
      [ALARM_LIST_STEPS.declareTicketFail]: 'Déclaration de ticket en échec',
      [ALARM_LIST_STEPS.declareTicketRuleInProgress]: 'Règle de déclaration de ticket exécutée par {author} en cours',
      [ALARM_LIST_STEPS.declareTicketRuleComplete]: 'Règle de déclaration du ticket terminée',
      [ALARM_LIST_STEPS.declareTicketRuleFail]: 'Règle de déclaration de ticket en échec',
      [ALARM_LIST_STEPS.instructionStart]: 'Remédiation démarrée',
      [ALARM_LIST_STEPS.instructionPause]: 'Remédiation suspendue',
      [ALARM_LIST_STEPS.instructionResume]: 'Reprise de la remédiation',
      [ALARM_LIST_STEPS.instructionComplete]: 'Remédiation terminée',
      [ALARM_LIST_STEPS.instructionAbort]: 'Remédiation interrompue',
      [ALARM_LIST_STEPS.instructionFail]: 'Remédiation en échec',
      [ALARM_LIST_STEPS.autoInstructionStart]: 'Remédiation automatique démarrée',
      [ALARM_LIST_STEPS.autoInstructionComplete]: 'Remédiation automatique terminée',
      [ALARM_LIST_STEPS.autoInstructionFail]: 'Remédiation automatique en échec',
      [ALARM_LIST_STEPS.junitTestSuiteUpdate]: 'La suite de test est relancée',
      [ALARM_LIST_STEPS.junitTestCaseUpdate]: 'Le cas de test est relancé',
      [ALARM_LIST_STEPS.metaalarmattach]: 'Alarme liée à la méta-alarme',
      [ALARM_LIST_STEPS.metaalarmdetach]: 'Alarme détachée de la méta-alarme',
      [ALARM_LIST_STEPS.comment]: 'Commentaire ajouté',
    },
  },
  tabs: {
    moreInfos: 'Plus d\'infos',
    timeLine: 'Chronologie',
    charts: 'Graphiques',
    alarmsChildren: 'Alarmes liées',
    trackSource: 'Cause racine',
    impactChain: 'Chaîne d\'impact',
    entityGantt: 'Diagramme de Gantt',
    ticketsDeclared: 'Tickets déclarés',
    remediation: 'Remédiation',
  },
  moreInfos: {
    defineATemplate: 'Pour définir le template de cette fenêtre, rendez-vous dans les paramètres du bac à alarmes.',
  },
  infoPopup: 'Info popup',
  tooltips: {
    priority: 'La priorité est égale à la sévérité multipliée par le niveau d\'impact de l\'entité sur laquelle l\'alarme est déclenchée',
    runningManualInstructions: 'Consigne manuelle <strong>{title}</strong> en cours | Consignes manuelles <strong>{title}</strong> en cours',
    runningAutoInstructions: 'Consigne automatique <strong>{title}</strong> en cours | Consignes automatiques <strong>{title}</strong> en cours',
    successfulManualInstructions: 'Consigne manuelle <strong>{title}</strong> réussie | Consignes manuelles <strong>{title}</strong> réussies',
    successfulAutoInstructions: 'Consigne automatique <strong>{title}</strong> réussies | Consignes automatiques <strong>{title}</strong> réussies',
    failedManualInstructions: 'Consigne manuelle <strong>{title}</strong> en échec | Consignes manuelles <strong>{title}</strong> en échec',
    failedAutoInstructions: 'Consigne automatique <strong>{title}</strong> en échec | Consignes automatiques <strong>{title}</strong> en échec',
    hasManualInstruction: 'Il y a une consigne manuelle associée | Il y a des consignes manuelles associées',
    resetChangeColumns: 'Réinitialiser l\'ordre/le redimensionnement des colonnes',
    startChangeColumns: 'Commencer à modifier l\'ordre/le redimensionnement des colonnes',
    finishChangeColumns: 'Terminer la modification de l\'ordre/du redimensionnement des colonnes',
  },
  metrics: {
    [ALARM_METRIC_PARAMETERS.createdAlarms]: 'Nombre d\'alarmes créées',
    [ALARM_METRIC_PARAMETERS.activeAlarms]: 'Nombre d\'alarmes actives',
    [ALARM_METRIC_PARAMETERS.nonDisplayedAlarms]: 'Nombre d\'alarmes non affichées',
    [ALARM_METRIC_PARAMETERS.instructionAlarms]: 'Nombre d\'alarmes en cours de remédiation automatique',
    [ALARM_METRIC_PARAMETERS.pbehaviorAlarms]: 'Nombre d\'alarmes avec comportement périodique',
    [ALARM_METRIC_PARAMETERS.correlationAlarms]: 'Nombre d\'alarmes corrélées',
    [ALARM_METRIC_PARAMETERS.ackAlarms]: 'Nombre d\'alarmes avec acquittement',
    [ALARM_METRIC_PARAMETERS.ackActiveAlarms]: 'Nombre d\'alarmes actives avec acquittement',
    [ALARM_METRIC_PARAMETERS.cancelAckAlarms]: 'Nombre d\'alarmes avec acquittement annulé',
    [ALARM_METRIC_PARAMETERS.ticketActiveAlarms]: 'Nombre d\'alarmes actives avec tickets',
    [ALARM_METRIC_PARAMETERS.withoutTicketActiveAlarms]: 'Nombre d\'alarmes actives sans tickets',
    [ALARM_METRIC_PARAMETERS.ratioCorrelation]: '% d\'alarmes corrélées',
    [ALARM_METRIC_PARAMETERS.ratioInstructions]: '% d\'alarmes avec remédiation automatique',
    [ALARM_METRIC_PARAMETERS.ratioTickets]: '% d\'alarmes avec tickets créés',
    [ALARM_METRIC_PARAMETERS.ratioRemediatedAlarms]: '% d\'alarmes remédiées manuellement',
    [ALARM_METRIC_PARAMETERS.ratioNonDisplayed]: '% d\'alarmes non affichées',
    [ALARM_METRIC_PARAMETERS.averageAck]: 'Délai moyen d\'acquittement des alarmes',
    [ALARM_METRIC_PARAMETERS.averageResolve]: 'Délai moyen de résolution des alarmes',
    [ALARM_METRIC_PARAMETERS.timeToAck]: 'Délai d\'acquittement des alarmes',
    [ALARM_METRIC_PARAMETERS.timeToResolve]: 'Délai de résolution des alarmes',
    [ALARM_METRIC_PARAMETERS.minAck]: 'Temps minimum pour acquitter les alarmes',
    [ALARM_METRIC_PARAMETERS.maxAck]: 'Délai maximum pour acquitter les alarmes',
    [ALARM_METRIC_PARAMETERS.manualInstructionExecutedAlarms]: 'Nombre d\'alarmes remédiées manuellement',
    [ALARM_METRIC_PARAMETERS.manualInstructionAssignedAlarms]: 'Nombre d\'alarmes avec remédiation manuelle',
    [ALARM_METRIC_PARAMETERS.notAckedAlarms]: 'Nombre d\'alarmes non acquittées',
    [ALARM_METRIC_PARAMETERS.notAckedInHourAlarms]: 'Nombre d\'alarmes non acquittées avec durée 1-4h',
    [ALARM_METRIC_PARAMETERS.notAckedInFourHoursAlarms]: 'Nombre d\'alarmes non acquittées avec durée 4-24h',
    [ALARM_METRIC_PARAMETERS.notAckedInDayAlarms]: 'Nombre d\'alarmes non acquittées de plus de 24h',
    [ALARM_METRIC_PARAMETERS.minResolve]: 'Temps minimum pour résoudre les alarmes',
    [ALARM_METRIC_PARAMETERS.maxResolve]: 'Temps max pour résoudre les alarmes',
  },
  fields: {
    displayName: 'Nom simplifié (DisplayName)',
    assignedInstructions: 'Consignes assignées',
    initialOutput: 'Sortie initiale longue',
    initialLongOutput: 'Sortie longue initiale',
    lastCommentInitiator: 'Initiateur du dernier commentaire',
    ackBy: 'Acquitté par',
    ackMessage: 'Message de l\'acquittement',
    ackInitiator: 'Origine de l\'acquittement',
    stateMessage: 'Message d\'état',
    statusMessage: 'Message de statut',
    totalStateChanges: 'Nombre de changements d\'état',
    stateValue: 'Valeur d\'état',
    statusValue: 'Valeur de statut',
    ackAt: 'Acquitté à',
    stateAt: 'État changé à',
    statusAt: 'Statut a changé à',
    resolved: 'Résolue à',
    activationDate: 'Date d\'activation',
    currentStateDuration: 'Durée de l\'état actuel',
    snoozeDuration: 'Durée de mise en veille',
    pbhInactiveDuration: 'Durée d\'inactivité (comportement périodique)',
    activeDuration: 'Durée active',
    eventsCount: 'Nombre d\'événements',
    extraDetails: 'Détails supplémentaires',
    alarmInfos: 'Informations sur l\'alarme',
    ticketAuthor: 'Auteur du ticket',
    ticketId: 'ID du ticket',
    ticketMessage: 'Message du ticket',
    ticketInitiator: 'Initiateur du ticket',
    ticketCreatedAt: 'Ticket créé à',
    ticketData: 'Données du ticket',
    entityName: 'Nom de l\'entité',
    entityCategoryName: 'Nom de la catégorie d\'entité',
    entityType: 'Type d\'entité',
    entityComponent: 'Composant d\'entité',
    entityConnector: 'Connecteur d\'entité',
    entityImpactLevel: 'Niveau d\'impact de l\'entité',
    entityKoEvents: 'Événements d\'entité KO',
    entityOkEvents: 'Événements d\'entité OK',
    entityInfos: 'Informations sur l\'entité',
    entityComponentInfos: 'Informations sur les composants de l\'entité',
    entityLastPbehaviorDate: 'Date du dernier comportement de l\'entité',
    openedChildren: 'Conséquences ouvertes',
    closedChildren: 'Conséquences fermées',
    canceledInitiator: 'Initiateur annulé',
    changeState: 'Changer d\'état',
  },
};
