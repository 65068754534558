export default {
  noTitle: '(no title)',
  today: 'Today',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  pbehaviorPlanningLegend: {
    title: 'Legend',
    noData: 'There aren\'t any exception dates on calendar',
  },
};
