import { render, staticRenderFns } from "./c-card-iterator-field.vue?vue&type=template&id=6c8cf897&"
import script from "./c-card-iterator-field.vue?vue&type=script&lang=js&"
export * from "./c-card-iterator-field.vue?vue&type=script&lang=js&"
import style0 from "./c-card-iterator-field.vue?vue&type=style&index=0&id=6c8cf897&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports