export const EVENT_TYPES = {
  ack: 'ack',
  ackRemove: 'ackremove',
  assocTicket: 'assocticket',
  cancel: 'cancel',
  uncancel: 'uncancel',
  changeState: 'changestate',
  check: 'check',
  comment: 'comment',
  snooze: 'snooze',
};
