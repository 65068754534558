<template>
  <top-bar-menu
    :title="$t('common.exploitation')"
    :links="links"
  />
</template>

<script>
import { ROUTES_NAMES, USERS_PERMISSIONS } from '@/constants';

import TopBarMenu from './top-bar-menu.vue';

export default {
  components: { TopBarMenu },
  computed: {
    links() {
      return [
        {
          route: { name: ROUTES_NAMES.exploitationDynamicInfos },
          icon: 'assignment',
          permission: USERS_PERMISSIONS.technical.exploitation.dynamicInfo,
        },
        {
          route: { name: ROUTES_NAMES.exploitationEventFilters },
          icon: 'policy',
          permission: USERS_PERMISSIONS.technical.exploitation.eventFilter,
        },
        {
          route: { name: ROUTES_NAMES.exploitationMetaAlarmRules },
          icon: '$vuetify.icons.insights',
          permission: USERS_PERMISSIONS.technical.exploitation.metaAlarmRule,
        },
        {
          route: { name: ROUTES_NAMES.exploitationPbehaviors },
          icon: 'pause',
          permission: USERS_PERMISSIONS.technical.exploitation.pbehavior,
        },
        {
          route: { name: ROUTES_NAMES.exploitationScenarios },
          icon: '$vuetify.icons.miscellaneous_services',
          permission: USERS_PERMISSIONS.technical.exploitation.scenario,
        },
        {
          route: { name: ROUTES_NAMES.exploitationSnmpRules },
          icon: 'approval',
          permission: USERS_PERMISSIONS.technical.exploitation.snmpRule,
        },
        {
          route: { name: ROUTES_NAMES.exploitationIdleRules },
          icon: '$vuetify.icons.published_with_changes',
          permission: USERS_PERMISSIONS.technical.exploitation.idleRules,
        },
        {
          route: { name: ROUTES_NAMES.exploitationFlappingRules },
          icon: 'swap_vert',
          permission: USERS_PERMISSIONS.technical.exploitation.flappingRules,
        },
        {
          route: { name: ROUTES_NAMES.exploitationResolveRules },
          icon: 'done_all',
          permission: USERS_PERMISSIONS.technical.exploitation.resolveRules,
        },
        {
          route: { name: ROUTES_NAMES.exploitationDeclareTicketRules },
          icon: 'local_play',
          permission: USERS_PERMISSIONS.technical.exploitation.declareTicketRule,
        },
        {
          route: { name: ROUTES_NAMES.exploitationLinkRules },
          icon: 'link',
          permission: USERS_PERMISSIONS.technical.exploitation.linkRule,
        },
      ];
    },
  },
};
</script>
